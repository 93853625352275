@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mixins.scss';

.vision {
    padding-top: 103px;
    padding-bottom: 103px;
    display: flex;

    @media screen and (max-width: 991px) {
        flex-direction: column;
    }


    &-texts {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    &-header {
        @include text (38px, 300, $text-dark, 1.2);
    }

    &-text {
        @include text (14px, 400, $text-light-gray, 2.2);

        @media screen and (max-width: 991px) {
            margin: 1em 0;
        }
    }

    &-img {
        width: min-content;
    }

    &-main-image {
        width: 45%;
        margin: 2em;
        margin-left: 0;

        @media screen and (max-width: 991px) {
            width: 90%;
        }
    }
}