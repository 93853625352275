@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.footer {
    display: flex;
    flex-direction: column;
    background-color: $footer-bgc;

    &-top {
        margin: 3em 0;
        flex-wrap: wrap;
        p {
            @include text(14px, 400, $text-light-gray, 2);

        }

        @media (min-width: 768px) {
            max-width: 50%;
        }

        @media (min-width: 992px) {
            max-width: 25%;
        }

    }

    &-nav {
        background-color: $bgc-black;

        &-nav {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 20px 0;

            @media screen and (max-width: 991px) {
                flex-direction: column-reverse;
                align-items: center;
            }
        }
        p {
            @include text(14px, 400, $footer-nav-text, 1.5)
        }

        nav {
            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin: 1em 0;
                
                li {
                    .link {
                        padding: 21px 17px;
                        margin-left: 13px;
                        @include text(14px, 400, $footer-nav-text, 1.5);
                        text-wrap: none;
                        margin: 1em 0;

                        @media screen and (max-width: 991px) {
                            padding: 1em;
                        }
                    }
                }
            }
        }
    }
}