@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mixins.scss';

.yandex {
    position: relative;
    padding: 110px 0 135px;
    transition: none;

    @media screen and (max-width: 479px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 50px 0 0px;
    }

    &-sticker {
        z-index: 99;
        position: relative;
        background-color: $bgc-blue;
        max-width: 293px;
        padding: 79px 25px 75px;
        transition: none;


        @media screen and (max-width: 479px) {
            max-width: 100%;
        }

        &-text {
            @include text (14px, 400, $text-white, 2);
            margin: 1em 0;
            transition: none;
        }

        &-logo {
            width: 100%;
            transition: none;
        }
    }
    &-map {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        transition: none;


        @media screen and (max-width: 479px) {
            position: relative;
            height: 400px;
            margin-top: 70px;
        }
    }
}