@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mixins.scss';


.integration-consultation {
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 20px 20px 0;

    @media screen and (max-width: 991px) {
        width: 100%;
    }

    &-booklet, &-contacts {
        background-color: $bgc-blue;
        @include text (14px, 600, $text-white, normal);
        cursor: pointer;
        text-align: center;
        min-width: 186px;
        padding: 23px 47px;
        text-transform: uppercase;
        display: inline-block;
        border: none;
        border-radius: 8pt;
    }

    &-contacts {
        width: min-content;
    }

    &-product {
        background-color: $bgc-gray;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 60px 39px 58px;
        margin-bottom: 33px;
        margin-top: 36px;
    }

    &-text {
        h2 {
            @include text (22px, 300, $text-white, 1.2);
            margin-bottom: 25px;
        }

        p {
            @include text (14px, 400, $text-light-gray, 2);
            margin-bottom: 50px;
        }
    }
}