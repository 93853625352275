@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.list {

    display: grid;
    grid-template-columns: 1fr 2fr;
    padding-top: 2em;

    @media screen and (max-width: 991px) {
        grid-template-columns: 1fr;
    }


    .catalog-list {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 20px 0;
    }

    .catalog-item {
        cursor: pointer;
        padding: 15px 29px;
        margin-bottom: 8px;
        @include text (20px, 400, $text-white, 1.45);
        background-color: $bgc-gray;
        transition: $bgc-gray 0.3s ease, $bgc-blue 0.3s ease;
    }

    .catalog-item:hover {
        background-color: $bgc-blue;
    }

    .selected {
        background-color: $bgc-blue;
    }

    .list-header {
        @include text (35px, 300, $integration-offer-header, 1.2);
        margin: 1em 0;
    }

    .list-description {
        @include text (14px, 400, $text-light-gray, 2);
        margin: 0.5em 0;
    }

    .link-to-pdf {
        display: flex;
        align-items: center;
        margin-top: 1.5em;

        p {
            margin-left: 1em;
            line-height: 23px;
            @include text(20px, 700, $text-light-gray, 2);
        }

    }
}