@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mixins.scss';

.experiments {
    &-header {
        width: 100%;
        text-align: center;
        @include text (38px, 300, $text-dark, 1.3);
        margin: 1.5em 0;
    }
}