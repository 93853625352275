@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mixins.scss';

.solution-products {
    background-color: $bgc-dark-blue;
    padding-bottom: 2em;

    .list-header {
        @include text (35px, 300, $text-white, 1.2);
        margin: 1em 0;
    }
}