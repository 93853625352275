@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.header.home {
    .logo {
        display: none;
    }

    .header-nav {
        margin-left: 10vw;
    }
}

.header {
    &-top {
        background-color: $bgc-black;
        display: flex;
        justify-content: space-between;
        padding: 14px 40px;

        p {
            @include text(12px, 400, $text-light-gray, 1px);
        }

        @media (max-width: 767px) {
            flex-direction: column;
            text-align: center;

            p {
                padding: 0.7em;
                line-height: 1.5;
            }
        }
    }
    &-nav {
        display: flex;
        align-items: center;
        background-color: $bgc-white;

        .logo {
            margin-right: 100px;
            margin-left: 63px;
        }
        ul {
            li {
                display: inline-block;

                .link {
                    margin: 32px 16px;
                    margin-right: 35px;
                    @include text(15px, 600, $nav-text, 2);
                    display: block;
                }
            }
        }
    }
}

.burger {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    
    &-text {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0.438em 0.625em;
        background-color: $bgc-dark;
        border-radius: 4px;
        p {
            @include text(16px, 700, $text-white, 1.188)
        }
    }

    &-nav {
        background-color: $bgc-dark;
        margin-top: 20px;
        width: 98vw;

        ul {
            padding: 20px;
            li {
                .burger-link {
                    @include text(0.875em, 600, $text-white, 2);
                }
            }
        }
    }
}