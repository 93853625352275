@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mixins.scss';

.progresses {
    background-image: url('../../../assets/images/about-progress.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;


    &-filling {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: start;

        @media screen and (max-width: 991px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 576px) {
            grid-template-columns: 1fr;
        }
    }

    .progress {
        display: flex;
        align-items: end;
        justify-content: center;
        margin: 1em 0;

        &-texts {
            display: flex;
            flex-direction: column;
            margin-left: 1em;
        }

        &-header {
            @include text (18px, 600, $text-white, 1.5);
        }

        &-text-span {
            @include text (60px, 300, $text-blue, 0.9);
        }

        &-text {
            @include text (14px, 400, $text-light-gray, 2);
        }

        &-img {
            height: min-content;
        }
    }

    &-img {
        background: rgb(4 4 37 / 92%);
        padding-top: 135px;
        padding-bottom: 105px;
    }
}