$text-white: #fff;
$bgc-white: #fff;
$text-blue: #5C97F9;
$bgc-blue: #5C97F9;
$text-light-gray: #718090;
$text-dark: #081624;
$bgc-dark: #081624;
$bgc-gray: #304a5f;
$bgc-black: #1E2025;
$bgc-dark-blue: #0e2b43;
$nav-text: #212529;
$footer-bgc: #292B30;
$footer-nav-text: #646470;
$main-products-border: #d6f1ff;
$main-call-btn-bgc: #2F3237;
$integration-offer-header: #364A5D;