@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.grids {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 56px;
    padding-bottom: 30px;

    @media screen and (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
    @media screen and (max-width: 450px) {
        margin: 0 auto;
    }

    .grid {
        display: flex;
        flex-direction: column;
        z-index: 99;
        background-color: $bgc-white;
        padding: 2em;

        &-top {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }

        &-header {
            @include text (18px, 600, $text-dark, 1.2);
            margin-left: 1em;
        }

        &-text {
            @include text (14px, 400, $text-light-gray, 2)
        }
    }
}