@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mixins.scss';

.main-photo {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    .photo {
        width: 100vw;
        height: 777px;
        transition: 0.5s ease-in-out;

        @media screen and (max-width: 450px) {
            height: 500px;
        }

        &-filling {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: start;
            height: 100%;
            transition: 0.5s ease-in-out;
            margin-left: 3em;
            
            @media screen and (max-width: 450px) {
                align-items: center;
                text-align: center;
                justify-content: space-between;
                margin-left: 0;
            }
        }
        
        &-header {
            @include text(42px, 300, $text-white, 1.5);
            width: 40vw;
            opacity: 0;
            transform: translateY(50px);
            transition: opacity 0s ease, transform 0.5s ease;
            &.fade-in {
              opacity: 1;
              transform: translateY(0);
            }
            &.fade-out {
              opacity: 0;
            }
            
            @media screen and (max-width: 991px) {
                font-size: 36px;
            }
            
            @media screen and (max-width: 767px) {
                font-size: 32px;
                width: 100%;
            }
            
            @media screen and (max-width: 450px) {
                margin-top: 25%;
                font-size: 26px;
                text-align: center;
            }

            span {
                background-color: $bgc-blue;
                padding: 10px 25px;
                display: inline-block;
                margin-top: 10%;
                width: max-content;

                @media screen and (max-width: 1200px) {
                    width: min-content;
                }
            }
        }

        .more {
            @include text(14px, 600, $text-dark, normal);
            background-color: $bgc-white;
            padding: 23px 47px;
            border: none;
            border-radius: 8pt;
            min-width: 186px;
            text-transform: uppercase;

            @media screen and (max-width: 450px) {
               margin-bottom: 3em;
            }
        }
    }
}

.polycamera {
    background-image: url('../../../assets/images/main-polycamera.jpg');
}

.recorder {
    background-image: url('../../../assets/images/main-recorder.jpg');
}

.polycamera, .recorder {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.main-btn {
    position: absolute;
    width: 80px;
    height: 80px;
    font-size: 25px;
    color: #081624;
    background: #fff;
    padding: 1em;
    border: none;
    top: 50%;

    @media screen and (max-width: 1200px) {
        width: 70px;
        height: 70px;
        font-size: 20px;
    }

    @media screen and (max-width: 991px) {
        width: 60px;
        height: 60px;
        font-size: 18px;
    }

    @media screen and (max-width: 767px) {
        width: 40px;
        height: 40px;
        font-size: 14px;
    }

    &-prev { 
        left: 0;
    }
    &-next { 
        right: 0;
    }
}

.photo-header.fade-out {
    opacity: 0;
}

.photo-header.fade-in {
    opacity: 1;
    transform: translateY(0);
}