@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/mixins.scss';

.presentation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 103px 0;

    @media (max-width: 991px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2em;
    }

    &-text {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        @media (max-width: 991px) {
            height: 300px;
            align-items: center;
        }

    }
    
    &-video {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;

        .react-player {
            max-width: 80%;
            margin: 2em 0;
            height: 230px;
        }
        

        @media (max-width: 991px) {
            .react-player {
                max-width: 80vw;
                max-height: 45vw;
            }
        }
    }

    &-header {
        @include text (38px, 300, $text-dark, 1.2);
        @media (max-width: 991px) {
            text-align: center;
        }
    }

    &-btn {
        background-color: $bgc-blue;
        border-radius: 8pt;
        width: max-content;
        min-width: 186px;
        padding: 23px 47px;
        text-transform: uppercase;
        @include text (14px, 600, $text-white, normal);
    }

    &-link {
        @include text (14px, 400, $text-light-gray, 2);
    }
}